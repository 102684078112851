import * as React from 'react';
import { Container, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import MasterContext from "./Misc/MasterContext";
import Login from './Login/Login';
import LocalPermissionsService from 'Services/local-permissions-service';
import SsoLogin from './Login/SsoLogin';
import LoginService from '../../Services/Login/Service/Login.service';
import './css/normalize.css'
import './css/site-layout.css'

export class IProps {
    refreshLogin: Function;
    checkingSso: boolean;
}
export class IState {
    forecasts: any[];
    loading: boolean;
    isOpenManage: boolean;
    isOpenRegular: boolean;
    isOpenPromotions: boolean;
    isOpenEngine: boolean;
    isOpenReports: boolean;
    isOpenAdmin: boolean;
    isOpenUser: boolean;
    menuFadeSpeed: number;
    reports: any[];
    ssoEnabled: boolean;
    ssoRedirectUrl: string;
    checkingSso: boolean;
    toggleMenuCollapse: boolean;
    backdoor: boolean;
}
export class Layout extends React.Component<IProps, IState> {
    static displayName = Layout.name;
    constructor(props: any) {
        super(props);
        this.state = {
            forecasts: [],
            loading: true,
            isOpenManage: false,
            isOpenRegular: false,
            isOpenPromotions: false,
            isOpenEngine: false,
            isOpenReports: false,
            isOpenAdmin: false,
            isOpenUser: false,
            menuFadeSpeed: 200,
            reports: [],
            ssoEnabled: false,
            ssoRedirectUrl: '',
            checkingSso: this.props.checkingSso,
            toggleMenuCollapse: false,
            backdoor: window.location.hash.includes("backdoor=true")
        };

        this.refreshLogin = this.refreshLogin.bind(this);
        this.registerReport = this.registerReport.bind(this);
        this.getReports = this.getReports.bind(this);
        this.getMenuClasses = this.getMenuClasses.bind(this);
        this.toggleMaster = this.toggleMaster.bind(this);
    }
    toggleMaster() {
        this.setState({ toggleMenuCollapse: !this.state.toggleMenuCollapse });
    }
    getMenuClasses(): string {
        let retVal: string;
        retVal = 'collapse navbar-collapse';
        if (this.state.toggleMenuCollapse === true) {
            retVal += ' show';
        }
        return retVal;
    }

    registerReport(reportName: string) {
        MasterContext.TableauReportSREF = reportName;
        this.leaveMenuReports();
    }
    getReports() {
        let retVal: any[] = [];

        for (let itm of this.state.reports) {
            if (itm.sref === null && itm.items) {
                let retVal2: any[] = [];
                for (let itm2 of itm.items) {
                    retVal2.push(<li key={itm2.label}>

                        <ul style={{ marginLeft: "-40px" }}>
                            <NavItem>
                                <NavLink tag={Link} style={{ marginLeft: '-8px', marginTop: '-10px', marginBottom: '-10px' }} to="/tableauhost"><span onClick={() => { this.registerReport(itm2.sref) }} className="dropdown-item" >{itm2.label}</span></NavLink>
                            </NavItem>
                        </ul>
                    </li>)
                }
                retVal.push(<li key={itm.label} className="dropdown-submenu">

                    <div className="row"><div className="col-9"><span className="dropdown-item" >{itm.label}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                    <ul className="dropdown-menu">
                        {retVal2}
                    </ul></li>)
            }
            else {
                retVal.push(<li key={itm.label}>
                    <ul style={{ marginLeft: "-40px" }}>
                        <NavItem>
                            <NavLink tag={Link} style={{ marginLeft: '-8px', marginTop: '-10px', marginBottom: '-10px' }} to="/tableauhost"><span onClick={() => { this.registerReport(itm.sref) }} className="dropdown-item" >{itm.label}</span></NavLink>
                        </NavItem>
                    </ul>
                </li>
                );
            }

        }
        return retVal;
    }

    componentDidMount() {
        this.getSsoRedirectUrl();
        this.getSsoEnabled();

        MasterContext.TableauService.getReports((e) => {
            this.setState({ reports: e[0].items }, () => {
            })
        }, () => { })
        //.then((e) => {
        //    this.setState({ reports: e[0].items }, () => {
        //        console.log(this.state.reports);
        //    })
        //})
        //.catch((err) => {

        //})

        this.setState({ loading: false });
    }

    toggleAllOff = () => {
        this.setState({ isOpenManage: false, isOpenRegular: false, isOpenPromotions: false, isOpenEngine: false, isOpenReports: false, isOpenAdmin: false, isOpenUser: false });
    }
    toggleMenuOpenManage = () => {
        this.toggleAllOff();
        this.setState({ isOpenManage: !this.state.isOpenManage });
    }

    toggleMenuOpenRegular = () => {
        this.toggleAllOff();
        this.setState({ isOpenRegular: !this.state.isOpenRegular });
    }

    toggleMenuOpenPromotions = () => {
        this.toggleAllOff();
        this.setState({ isOpenPromotions: !this.state.isOpenPromotions });
    }

    toggleMenuOpenEngine = () => {
        this.toggleAllOff();
        this.setState({ isOpenEngine: !this.state.isOpenEngine });
    }

    toggleMenuOpenReports = () => {
        this.toggleAllOff();
        this.setState({ isOpenReports: !this.state.isOpenReports });
    }

    toggleMenuOpenAdmin = () => {
        this.toggleAllOff();
        this.setState({ isOpenAdmin: !this.state.isOpenAdmin });
    }

    toggleMenuOpenUser = () => {
        this.toggleAllOff();
        this.setState({ isOpenUser: !this.state.isOpenUser });
    }

    leaveMenuManage = () => {
        let me = this;
        setTimeout(function () { me.setState({ isOpenManage: false }); me.toggleMaster() }, this.state.menuFadeSpeed);
    }

    leaveMenuRegular = () => {
        let me = this;
        setTimeout(function () { me.setState({ isOpenRegular: false }); me.toggleMaster() }, this.state.menuFadeSpeed);
    }

    leaveMenuPromotions = () => {
        let me = this;
        setTimeout(function () { me.setState({ isOpenPromotions: false }); me.toggleMaster() }, this.state.menuFadeSpeed);
    }

    leaveMenuEngine = () => {
        let me = this;
        setTimeout(function () { me.setState({ isOpenEngine: false }); me.toggleMaster() }, this.state.menuFadeSpeed);
    }

    leaveMenuReports = () => {
        let me = this;
        setTimeout(function () { me.setState({ isOpenReports: false }); me.toggleMaster() }, this.state.menuFadeSpeed);
    }

    leaveMenuAdmin = () => {
        let me = this;
        setTimeout(function () { me.setState({ isOpenAdmin: false }); me.toggleMaster() }, this.state.menuFadeSpeed);
    }

    leaveMenuUser = () => {
        let me = this;
        setTimeout(function () { me.setState({ isOpenUser: false }); me.toggleMaster() }, this.state.menuFadeSpeed);
    }

    someAction() { }



    refreshLogin() {

        this.forceUpdate();
        this.props.refreshLogin();
    }

    getLoginState(): boolean {
        let retVal = false;

        if (MasterContext.UserData.userName !== '') {
            retVal = true;
        }

        return retVal;
    }

    getSsoEnabled() {
        let LS: LoginService = new LoginService();
        LS.ssoEnabled(() => {
            this.setState({ ssoEnabled: true, checkingSso: false });
        }, () => {
            this.setState({ checkingSso: false })
        });
    }

    getSsoRedirectUrl() {
        let LS: LoginService = new LoginService();
        LS.ssoRedirectUrl((resp) => {
            this.setState({ ssoRedirectUrl: resp });
            console.log(`redirect url: ${resp}`);
        });
    }

    getUserName(): string {
        let retVal = '';

        retVal = MasterContext.UserData.userName;

        return retVal;
    }
    
    render() {
        let menuClassManage = `dropdown-menu${this.state.isOpenManage ? " show" : ""}`;
        let menuClassRegular = `dropdown-menu${this.state.isOpenRegular ? " show" : ""}`;
        let menuClassPromotions = `dropdown-menu${this.state.isOpenPromotions ? " show" : ""}`;
        let menuClassEngine = `dropdown-menu${this.state.isOpenEngine ? " show" : ""}`;
        let menuClassReports = `dropdown-menu${this.state.isOpenReports ? " show" : ""}`;
        let menuClassAdmin = `dropdown-menu${this.state.isOpenAdmin ? " show" : ""}`;
        let menuClassUser = `dropdown-menu${this.state.isOpenUser ? " show" : ""}`;
        let navbarNavText = { color: '#333', fontFamily: 'Roboto, Tahoma, Geneva, sans-serif', fontSize: '19px', marginRight: '20px !important' };
        let logoStyle = {
            width: '-webkit-fill-available',
            cursor: 'pointer',
            marginTop: '-10px'
        };
        let maxDiv = { width: '100%' };
        let fixMargin = { marginLeft: '-8px', marginTop: '-10px', marginBottom: '-10px'};
        let fixMarginUser = { marginTop: '-10px', marginBottom: '-10px' };
        let fixMarginHome = { marginTop: '-10px', marginBottom: '-10px' };
        let fixOuterUL = { marginLeft: "-40px" }
        let blackFont = { color: "#000000", fontFamily: 'Roboto, Tahoma, Geneva, sans-serif', fontSize: '18px' };
        let inLineItem = { display: 'inline', float: 'left' };
        let inLineItem2 = { display: 'inline', float: 'left', marginTop: "-6px" };
        let inLineList = { display: 'inline' };
        let posAbs = { position: 'absolute' };
        let containerMinHeight = { minHeight: '84vh' };
        let padRightSide = { paddingRight: '30px' };

        let outer = { height: "100%", margin: "0" };
        let main = { margin: "0 auto", width: "700px", padding: "30px", font: "14px Helvetica" };

        const styles = {
            alignCenter: { textAlign: 'center' },
        } as const;

        if (this.state.checkingSso) {
            return (
                 <div className="loginBody">
                    <div className="row" style={{ width: "100%", padding: "0 0 45px 0" }}>
                        <div className="col-md-12 text-center">
                            <img alt='' src="https://cleardemand.com/wp-content/uploads/2023/08/loginLogo.png" /> <br />
                            <br />
                            <br />
                            <h2>Determining Login Method<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></h2>
                        </div>
                        
                    </div>
                </div>
            );
        }

        else if (this.state.ssoEnabled && !this.getLoginState() && !this.state.backdoor) {
            return (
                <SsoLogin handleLogin={this.refreshLogin} ssoRedirectUrl={this.state.ssoRedirectUrl} />
            );
        }

        else if (!this.getLoginState()) {
            return (
                <Login handleLogin={this.refreshLogin} />
            );
        }

        return (
            <div>
                <div style={{ zIndex: 3001 }} className="navbar navbar-default">
                    <div className="container-fluid">
                        <div style={maxDiv}>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="navbar-header" style={{ position: 'relative' }} title="Home">
                                        <NavItem>
                                            <NavLink tag={Link}  to="/"><img alt='' src="core/img/logo.png" style={logoStyle} /></NavLink>
                                        </NavItem>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <nav className="navbar navbar-expand-lg ">
                                        <button onClick={this.toggleMaster} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>

                                        <div style={{ zIndex: 3000 }} className={this.getMenuClasses()} id="navbarSupportedContent">
                                            <ul className="navbar-nav mr-auto">
                                                <li className="nav-item active">
                                                    <ul style={fixOuterUL}>
                                                        <NavItem>
                                                            <NavLink tag={Link} style={fixMarginHome} to="/">
                                                                {/* <span onClick={() => { this.toggleMaster(); this.toggleAllOff() }} className="nav-link" style={navbarNavText} ><i className='fa fa-home'></i>&nbsp; {MasterContext.translate('nav_menu_home')}  <span className="sr-only">(current)</span></span>*/}
                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </li>
                                                {/* Manage Section */}
                                                {
                                                    MasterContext.hasNavigationParentPermission('Manage') ?
                                                        <li className="nav-item dropdown">
                                                            <span style={navbarNavText} className="nav-link dropdown-toggle" onClick={this.toggleMenuOpenManage} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"> {MasterContext.translate('nav_menu_manage')}</span>
                                                            <ul className={menuClassManage} aria-labelledby="navbarDropdown">
                                                                {
                                                                    MasterContext.hasNavigationPermission('Manage > Rules') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/managerules"><span onClick={this.leaveMenuManage} className="dropdown-item" > {MasterContext.translate('nav_menu_manage_rules')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>

                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Manage > Product Groups') ?
                                                                        <li className="dropdown-submenu"><div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_manage_product_groups')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Product Groups > Search') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/manageproductgroupssearch"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_product_groups_search')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Product Groups > Search') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/manageproductgroupscreate"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_product_groups_create')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Product Groups > Import Groups') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/manageproductgroupsimport"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_product_groups_import')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Product Groups > Export Groups') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/manageproductgroupsexport"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_product_groups_export')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Manage > Location Groups') ?
                                                                        <li className="dropdown-submenu"><div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_manage_location_groups')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Location Groups > Search') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/managelocationgroupssearch"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_location_groups_search')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Location Groups > Create') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/managelocationgroupscreate"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_location_groups_create')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Location Groups > Import Groups') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/managelocationgroupsimport"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_location_groups_import')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Location Groups > Export Groups') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/managelocationgroupsexport"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_location_groups_export')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Manage > Scenarios') ?
                                                                        <li className="dropdown-submenu">
                                                                            <div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_manage_scenarios')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Scenarios > Manage') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/managescenariosmanage"><span onClick={this.leaveMenuManage} className="dropdown-item" >{MasterContext.translate('nav_menu_manage_scenarios_manage')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Manage > Scenarios > Create') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/managescenarioscreate"><span onClick={this.leaveMenuManage} className="dropdown-item" >{MasterContext.translate('nav_menu_manage_scenarios_create')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Manage > Dimensions') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/managedimensions"><span onClick={this.leaveMenuManage} className="dropdown-item">{MasterContext.translate('nav_menu_manage_manage_dimensions')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                            </ul>
                                                        </li>
                                                        : null
                                                }
                                                {/* Regular Section */}
                                                {
                                                    MasterContext.hasNavigationParentPermission('Regular')
                                                        && MasterContext.QueryModule('regular') ?
                                                        <li className="nav-item dropdown">
                                                            <span style={navbarNavText} className="nav-link dropdown-toggle" onClick={this.toggleMenuOpenRegular} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                {MasterContext.translate('nav_menu_regular')}
                                                            </span>
                                                            <ul className={menuClassRegular} aria-labelledby="navbarDropdown">
                                                                {
                                                                    MasterContext.hasNavigationPermission('Regular > Executive Summary') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/executivesummary"><span onClick={this.leaveMenuRegular} className="dropdown-item" >{MasterContext.translate('nav_menu_regular_executive_summary')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Regular > Price Review')
                                                                        && MasterContext.QueryModule('regular')
                                                                        && MasterContext.QueryModule('price review') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/pricereview"><span onClick={this.leaveMenuRegular} className="dropdown-item" >{MasterContext.translate('nav_menu_regular_price_review')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Regular > Rules Explorer') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/rulesexplorer"><span onClick={this.leaveMenuRegular} className="dropdown-item">{MasterContext.translate('nav_menu_regular_rules_explorer')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Regular > State Minimum Editor') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/stateminimum"><span onClick={this.leaveMenuRegular} className="dropdown-item">{MasterContext.translate('nav_menu_regular_state_minimum')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Regular > Buydown Explorer') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/buydowns"><span onClick={this.leaveMenuRegular} className="dropdown-item">{MasterContext.translate('nav_menu_regular_buydown')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                            </ul>
                                                        </li>
                                                        : null
                                                }
                                                {/* Promotion Section */}
                                                {
                                                    MasterContext.hasNavigationParentPermission('Promotions')
                                                        && MasterContext.QueryModule('promotion') ?
                                                        <li className="nav-item dropdown">
                                                            <span style={navbarNavText} className="nav-link dropdown-toggle" onClick={this.toggleMenuOpenPromotions} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">{MasterContext.translate('nav_menu_promotions')}</span>
                                                            <ul className={menuClassPromotions} aria-labelledby="navbarDropdown">
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Promotions > Events') ?
                                                                        <li className="dropdown-submenu">
                                                                            <div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_promotions_events')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Promotions > Events > Manage') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/promotionseventsmanage"><span onClick={this.leaveMenuPromotions} className="dropdown-item">{MasterContext.translate('nav_menu_promotions_events_manage')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Promotions > Events > Create') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/promotionseventcreate"><span onClick={this.leaveMenuPromotions} className="dropdown-item">{MasterContext.translate('nav_menu_promotions_events_create')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Promotions > Templates') ?
                                                                        <li className="dropdown-submenu">
                                                                            <div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_promotions_templates')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Promotions > Templates > Manage') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/promotionstemplatesmanage"><span onClick={this.leaveMenuPromotions} className="dropdown-item">{MasterContext.translate('nav_menu_promotions_templates_manage')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Promotions > Ad Sets') ?
                                                                        <li className="dropdown-submenu">
                                                                            <div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_promotions_ad_sets')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Promotions > Ad Sets > Manage') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/promotionsadsetsmanage"><span onClick={this.leaveMenuPromotions} className="dropdown-item">{MasterContext.translate('nav_menu_promotions_ad_sets_manage')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Promotions > Ad Sets > Create') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/promotionsadsetcreate"><span onClick={this.leaveMenuPromotions} className="dropdown-item">{MasterContext.translate('nav_menu_promotions_ad_sets_create')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                            </ul>
                                                        </li>
                                                        : null
                                                }
                                                {/* Engine Section */}
                                                {
                                                    MasterContext.hasNavigationParentPermission('Engine') ?
                                                        <li className="nav-item dropdown">
                                                            <span style={navbarNavText} className="nav-link dropdown-toggle" onClick={this.toggleMenuOpenEngine} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">{MasterContext.translate('nav_menu_engine')}</span>
                                                            <ul className={menuClassEngine} aria-labelledby="navbarDropdown">
                                                                {
                                                                    MasterContext.hasNavigationPermission('Engine > Run') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/enginerun"><span onClick={this.leaveMenuEngine} className="dropdown-item">{MasterContext.translate('nav_menu_engine_run')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Engine > Job Status') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/enginejobstatus"><span onClick={this.leaveMenuEngine} className="dropdown-item">{MasterContext.translate('nav_menu_engine_job_status')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Engine > Task Status') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/enginetaskstatus"><span onClick={this.leaveMenuEngine} className="dropdown-item">{MasterContext.translate('nav_menu_engine_task_status')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                            </ul>
                                                        </li>
                                                        : null
                                                }
                                                {/* Reports Section */}
                                                {
                                                    MasterContext.hasNavigationParentPermission('Reports') ?
                                                        <li className="nav-item dropdown">
                                                            <span style={navbarNavText} className="nav-link dropdown-toggle" onClick={this.toggleMenuOpenReports} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">{MasterContext.translate('nav_menu_reports')}</span>
                                                            <ul className={menuClassReports} aria-labelledby="navbarDropdown">
                                                                {this.getReports()}
                                                            </ul>
                                                        </li>
                                                        :
                                                        null
                                                }
                                                {/* Admin Section */}
                                                {
                                                    MasterContext.hasNavigationParentPermission('Admin') ?
                                                        <li className="nav-item dropdown">
                                                            <span style={navbarNavText} className="nav-link dropdown-toggle" onClick={this.toggleMenuOpenAdmin} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">{MasterContext.translate('nav_menu_admin')}</span>
                                                            <ul className={menuClassAdmin} aria-labelledby="navbarDropdown">
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Admin > Settings') ?
                                                                        <li className="dropdown-submenu">
                                                                            <div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_admin_settings')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Admin > Settings > Manage Grids') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/adminsettingsmanagegrids"><span onClick={this.leaveMenuAdmin} className="dropdown-item" >{MasterContext.translate('nav_menu_admin_settings_manage_grids')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Admin > Settings > Lock Days') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/adminsettingslockdays"><span onClick={this.leaveMenuAdmin} className="dropdown-item" >{MasterContext.translate('nav_menu_admin_settings_lock_days')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Admin > Settings > Price Change Days') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/adminpricechangedays"><span onClick={this.leaveMenuAdmin} className="dropdown-item" >{MasterContext.translate('nav_menu_admin_price_change_date_days')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Admin > Settings > Future Buydown Days') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/adminfuturebuydowndays"><span onClick={this.leaveMenuAdmin} className="dropdown-item" >{MasterContext.translate('nav_menu_admin_future_buydown_days')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/topfilteroptions"><span onClick={this.leaveMenuAdmin} className="dropdown-item" >{MasterContext.translate('nav_menu_admin_top_filter_options')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Admin > Users & Roles') ?
                                                                        <li className="dropdown-submenu">
                                                                            <div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_admin_users_roles')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                <li>
                                                                                    {
                                                                                        MasterContext.hasNavigationPermission('Admin > Users & Roles > Manage Users') ?
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/users"><span onClick={this.leaveMenuAdmin} className="dropdown-item">{MasterContext.translate('nav_menu_admin_manage_users')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        MasterContext.hasNavigationPermission('Admin > Users & Roles > Manage Roles') ?
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/roles"><span onClick={this.leaveMenuAdmin} className="dropdown-item">{MasterContext.translate('nav_menu_admin_manage_roles')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                            : null
                                                                                    }
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationParentPermission('Admin > Audits') ?
                                                                        <li className="dropdown-submenu">
                                                                            <div className="row"><div className="col-9"><span className="dropdown-item" >{MasterContext.translate('nav_menu_admin_audits')}</span></div><div className="col-3"><i className="fa fa-caret-right" aria-hidden="true"></i></div></div>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Admin > Audits > Audit History') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/adminaudithistory"><span onClick={this.leaveMenuAdmin} className="dropdown-item">{MasterContext.translate('nav_menu_admin_audit_history')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    MasterContext.hasNavigationPermission('Admin > Audits > User History') ?
                                                                                        <li>
                                                                                            <ul style={fixOuterUL}>
                                                                                                <NavItem>
                                                                                                    <NavLink tag={Link} style={fixMargin} to="/adminaudit"><span onClick={this.leaveMenuAdmin} className="dropdown-item">{MasterContext.translate('nav_menu_admin_audit_history_admin')}</span></NavLink>
                                                                                                </NavItem>
                                                                                            </ul>
                                                                                        </li>
                                                                                        : null
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Admin > Manage Reports Menu') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/adminmanagereportsmenu"><span onClick={this.leaveMenuAdmin} className="dropdown-item">{MasterContext.translate('nav_menu_admin_manage_reports_menu')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Admin > Manage Node Servers') ?
                                                                        <li>
                                                                            <ul style={fixOuterUL}>
                                                                                <NavItem>
                                                                                    <NavLink tag={Link} style={fixMargin} to="/adminmanagenodeservers"><span onClick={this.leaveMenuAdmin} className="dropdown-item">{MasterContext.translate('nav_menu_admin_manage_node_servers')}</span></NavLink>
                                                                                </NavItem>
                                                                            </ul>
                                                                        </li>
                                                                        : null
                                                                }
                                                                {
                                                                    MasterContext.hasNavigationPermission('Admin > Manage Department Notifications') ?
                                                                        <ul style={fixOuterUL}>
                                                                            <NavItem>
                                                                                <NavLink tag={Link} style={fixMargin} to="/managedepartmentnotifications"><span onClick={this.leaveMenuAdmin} className="dropdown-item">Manage Department Notifications</span></NavLink>
                                                                            </NavItem>
                                                                        </ul>
                                                                        : null
                                                                }
                                                            </ul>
                                                        </li>
                                                        : null
                                                }
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="col-md-3" style={padRightSide}>
                                    <nav className="pull-right">
                                        {/*
            // @ts-ignore */}
                                        <ul style={inLineList} className="nav navbar-nav notifications">
                                            {/*
            // @ts-ignore */}
                                            <li style={inLineItem}>
                         
                                                <span className="nav-link" style={blackFont}> Hello, {this.getUserName()}! </span>
                                               
                                            </li>

                                            {/*
            // @ts-ignore */}
                                            <li className="nav-link" style={inLineItem}>
                                                <ul>
                                                    <NavItem>
                                                        <NavLink tag={Link} style={fixMargin} to="/filemanager"><span onClick={this.toggleAllOff} className="dropdown-item"> <i title="File Explorer" className="fa fa-folder-o"></i></span></NavLink>
                                                    </NavItem>
                                                </ul>
                                            </li>

                                            {/*
            // @ts-ignore */}
                                            <li style={inLineItem} className="nav-item dropdown">
                                                <span style={blackFont} className="nav-link dropdown-toggle" onClick={this.toggleMenuOpenUser} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fa fa-gear"></i> Settings</span>
                                                {/*
            // @ts-ignore */}
                                                <ul className={menuClassUser} aria-labelledby="navbarDropdown" style={posAbs}>
                                                    {MasterContext.hasNavigationPermission('settings') ?
                                                        <li>
                                                            <ul style={fixOuterUL}>
                                                                <NavItem>
                                                                    <NavLink tag={Link} style={fixMarginUser} to="/settings"><span onClick={this.leaveMenuUser} className="dropdown-item" >{MasterContext.translate("nav_menu_settings")}</span></NavLink>
                                                                </NavItem>
                                                            </ul>
                                                        </li>
                                                        : <i />}
                                                    <li>
                                                        <ul style={fixOuterUL}>
                                                            <NavItem>
                                                                <NavLink tag={Link} style={fixMarginUser} to="/help"><span onClick={this.leaveMenuUser} className="dropdown-item">Help & Support</span></NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <ul style={fixOuterUL}>
                                                            <NavItem>
                                                                <NavLink tag={Link} style={fixMarginUser} to="/userprofile"><span onClick={this.leaveMenuUser} className="dropdown-item" >{MasterContext.translate("nav_menu_user_profile")}</span></NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <ul style={fixOuterUL}>
                                                            <NavItem>
                                                                <NavLink tag={Link} style={fixMarginUser} to="/changepassword"><span onClick={this.leaveMenuUser} className="dropdown-item">{MasterContext.translate("nav_menu_change_password")}</span></NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <ul style={fixOuterUL}>
                                                            <NavItem>
                                                                <NavLink tag={Link} style={fixMarginUser} to="/logout"><span onClick={this.leaveMenuUser} className="dropdown-item">{MasterContext.translate("nav_menu_logout")}</span></NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            {/*
            // @ts-ignore */}
                                           
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ clear: 'both' }}></div>


                <div style={containerMinHeight} onClick={this.toggleAllOff}>
                    <Container fluid={true}>
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}
